<div class="heading_top pl-8 text-2xl">
    <h1>Dashboard</h1>
</div>
<div class="mx-auto w-11/12 pt-11">
    <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li class="inline-flex items-center">
                <a href="/dashboard"
                    class="inline-flex gap-2 items-center text-base font-medium text-gray hover:text-hover_blue dark:text-gray-400">
                    <svg class="w-3 h-3 pr-3.9" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    Dashboard
                </a>
            </li>
        </ol>
    </nav>

    <div class="grid grid-cols-12 gap-4 mt-4">
        <div class="rounded-2xl col-span-6 gap-3 flex items-center border border-stroke bg-white px-5 py-6 shadow-lg">
            <div class="w-20 h-20 bg-border_bg rounded-lg p-5"><img src="../../../../assets/images/total-shippers.svg"
                    alt="" /></div>
            <div class="ml-auto text-right">
                <h4 class="text-4xl font-bold">{{dashBoardData?.activeUsers}}</h4>
                <p class="text-sm text-gray-500">Total Active Users</p>
            </div>
        </div>
        <div class="rounded-2xl col-span-6 gap-3 flex items-center border border-stroke bg-white px-5 py-6 shadow-lg">
            <div class="w-20 h-20 bg-border_bg rounded-lg p-5"><img src="../../../../assets/images/received.svg"
                    alt="" /></div>
            <div class="ml-auto text-right">
                <h4 class="text-4xl font-bold">{{dashBoardData?.noOfReviews}}</h4>
                <p class="text-sm text-gray-500">Total Reviews</p>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mt-4">
        <div class="rounded-2xl col-span-6 gap-3 flex items-center border border-stroke bg-white px-5 py-6 shadow-lg">
            <div class="w-20 h-20 bg-border_bg rounded-lg p-5"><img src="../../../../assets/images/weekly.svg" alt="" />
            </div>
            <div class="ml-auto text-right">
                <h4 class="text-4xl font-bold">{{dashBoardData?.totalWeeklyReviews}}</h4>
                <p class="text-sm text-gray-500">Total Weekly Reviews</p>
            </div>
        </div>
        <div class="rounded-2xl col-span-6 gap-3 flex items-center border border-stroke bg-white px-5 py-6 shadow-lg">
            <div class="w-20 h-20 bg-border_bg rounded-lg p-5"><img src="../../../../assets/images/weekly.svg" alt="" />
            </div>
            <div class="ml-auto text-right">
                <h4 class="text-4xl font-bold">{{dashBoardData?.totalMonthlyReviews}}</h4>
                <p class="text-sm text-gray-500">Total Monthly Reviews</p>
            </div>
        </div>
    </div>
</div>