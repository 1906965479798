import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  loginApi(body: any) {
    return this.http.post(this.baseUrl + `admin/auth/login`, body)
  }

  dashboardData(body: any) {
    return this.http.get(this.baseUrl + `admin/getDashboardDetails`)
    //  return this.http.post('https://doc-watch-5b78ed26b284.herokuapp.com/admin/getDashboardDetails', body)
  }

  /* Driver api */
  driversData(offset: any, limit: any, searchText?: any) {
    const queryParams = new URLSearchParams();
    queryParams.set('offset', (offset || 0).toString());
    queryParams.set('limit', (limit || 10).toString());

    if (searchText) {
      queryParams.set('search', searchText);
    }

    const url = this.baseUrl + `admin/driver/getAllDriver?` + queryParams.toString();
    return this.http.get(url);
  }

  driverDetail(id: any) {
    return this.http.get(this.baseUrl + `admin/driver/getDriver/${id}`)
  }

  deleteDriver(id: any) {
    return this.http.delete(this.baseUrl + `admin/driver/delete/${id}`)
  }

  suspendDriver(id: any, body: any) {
    return this.http.put(`${this.baseUrl}admin/driver/blockUnblock/${id}`, body);
  }

  /* Shipper-recievers */

  shippersData(offset: any, limit: any, searchText?: any) {
    const queryParams = new URLSearchParams()
    queryParams.set('offset', (offset || 0).toString())
    queryParams.set('limit', (limit || 10).toString())
    if (searchText) {
      queryParams.set('search', searchText)
    }

    const url = this.baseUrl + `admin/shipper/getAllShipper?` + queryParams.toString()
    return this.http.get(url)
  }

  shippersDetail(id: any) {
    return this.http.get(this.baseUrl + `admin/shipper/getShipper/${id}`)
  }

  suspendShipper(id: any, body: any) {
    return this.http.put(`${this.baseUrl}admin/shipper/blockUnblock/${id}`, body)
  }

  shipperDetail(id: any) {
    return this.http.get(this.baseUrl + `admin/shipper/getShipper/${id}`)
  }

  deleteShipper(id: any) {
    return this.http.delete(this.baseUrl + `admin/shipper/delete/${id}`)

  }

  acceptRejectShippers(id: any, body: any) {
    return this.http.put(`${this.baseUrl}admin/shipper/approve/${id}`, body)
  }



  /* shipper-review*/

  shipperReviewData(offset: any, limit: any, searchText?: any) {
    let queryParams = new URLSearchParams()
    queryParams.set('offset', (offset || 0).toString())
    queryParams.set('limit', (limit || 0).toString())
    queryParams.set('type', 'shipper')
    if (searchText) {
      queryParams.set('search', searchText)
    }
    const url = this.baseUrl + `admin/reviews/getAllReviews?` + queryParams.toString()
    return this.http.get(url)
  }

  shipperReviewDetail(id: any) {
    // /admin/reviews/getReview/{id}
    return this.http.get(this.baseUrl + `admin/reviews/getReview/${id}`)
  }



  /* receiver-review */

  receiverReviewData(offset: any, limit: any, searchText?: any) {
    let queryParams = new URLSearchParams()
    queryParams.set('offset', (offset || 0).toString())
    queryParams.set('limit', (limit || 0).toString())
    queryParams.set('type', 'receiver')
    if (searchText) {
      queryParams.set('search', searchText)
    }
    const url = this.baseUrl + `admin/reviews/getAllReviews?` + queryParams.toString()
    return this.http.get(url)
  }


  /* query management */

  queryManagementData(offset: any, limit: any, searchText?: any) {
    let queryParams = new URLSearchParams()
    queryParams.set('offset', (offset || 0).toString())
    queryParams.set('limit', (limit || 0).toString())
    if (searchText) {
      queryParams.set('search', searchText)
    }
    const url = this.baseUrl + `admin/support/getAllSupportQuestions?` + queryParams.toString()
    return this.http.get(url)
  }

  sendReply(body: any) {
    return this.http.post(this.baseUrl + `admin/sendQuickReply`, body)
  }


  /* settings*/

  changePassword(body: any) {
    return this.http.post(this.baseUrl + `admin/auth/changePassword`, body)
  }

}