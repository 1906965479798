import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { SidebarComponent } from '../commonpages/sidebar/sidebar.component';
import { HeaderComponent } from '../commonpages/header/header.component';
import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { DashboadinternalComponent } from './dashboadinternal/dashboadinternal.component';
import { DriversAccountComponent } from './drivers-account/drivers-account.component';
import { QueryManagementComponent } from './query-management/query-management.component';
import { SettingComponent } from './setting/setting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [SidebarComponent, HeaderComponent, DashboardComponent, DashboadinternalComponent, DriversAccountComponent, QueryManagementComponent, SettingComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class DashboardModule { }
